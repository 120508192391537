<template>
    <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
        <div id="navbarBasicExample" class="navbar-menu has-text-centered is-primary">
            <div class="navbar-start on-the-left is-pulled-left is-hidden-mobile">
                <a class="navbar-item">
                    <img :src="logo" height="28">
                </a>
            </div>
            <div class="navbar-start in-the-middle mobile-width">
                <router-link 
                    v-if="showButtons.stats" to="/admin/stats" 
                    class="navbar-item is-disabled" 
                    :class="{'is-active-page': currentRoute === 'stats'}"
                >
                    <i class="fas fa-chart-bar"></i>
                </router-link>
                <router-link v-if="showButtons.user" to="/admin/user" class="navbar-item" :class="{'is-active-page': currentRoute === 'user'}">
                    <i class="fas fa-user"></i>
                </router-link>
                <div class="navbar-item" :class="{'is-hidden': !upload || !upload.ing}">
                    <div id="progress"></div>
                </div>
                <div 
                    v-if="showButtons.editing" 
                    class="navbar-item has-dropdown has-dropdown-up is-active-page" 
                    :class="{'is-active': dropdown === 'edit', 'is-hidden': upload.ing || currentRoute !== 'edit'}"
                >
                    <a v-if="dropdown === 'edit'" class="navbar-link is-arrowless" @click="dismissDropdown">
                        <i class="fas fa-times-circle"></i>
                    </a>
                    <a v-else class="navbar-link is-arrowless" @click="toggleDropdown('edit')">
                        <i class="fas fa-plus-circle"></i>
                    </a>
                    <div v-if="page" class="navbar-dropdown is-boxed is-fullwidth" v-click-away="onClickAway">
                        <a class="navbar-item" @click="emitButtonClick({action: 'add', elem: 'link'})">
                            <i class="fas fa-link"></i> <span class="item-text">Add Link</span>
                        </a>
                        <a class="navbar-item" @click="emitButtonClick({action: 'add', elem: 'heading'})">
                            <i class="fas fa-heading"></i> <span class="item-text">Add Heading</span>
                        </a>
                        <a class="navbar-item" @click="emitButtonClick({action: 'add', elem: 'socials'})">
                            <i class="fas fa-heart"></i> <span class="item-text">Add Socials</span>
                        </a>
                        <hr class="dropdown-divider" />
                        <a class="navbar-item" @click="emitButtonClick({action: 'edit', elem: 'profilePic'})">
                            <i class="fas fa-user-circle"></i> <span class="item-text">Change Profile Pic</span>
                        </a>
                        <a class="navbar-item" @click="emitButtonClick({action: 'toggle', elem: 'banner'})">
                            <i class="fas fa-image"></i> <span v-if="banner.show" class="item-text">Hide Banner Image</span>
                            <span v-else class="item-text">Show Banner Image</span>
                        </a>
                        <a class="navbar-item" @click="emitButtonClick({action: 'edit', elem: 'background'})">
                            <i class="fas fa-image"></i> <span class="item-text">Change Background</span>
                        </a>
                        <hr class="dropdown-divider" />
                        <a class="navbar-item is-disabled" disabled="true">
                            <i class="fas fa-image"></i> <span class="item-text">Edit Design</span>
                        </a>
                    </div>
                </div>
                <router-link v-if="showButtons.edit" to="/admin/edit" class="navbar-item" :class="{'is-hidden': upload.ing || currentRoute == 'edit'}">
                    <i class="fas fa-pen"></i>
                </router-link>
                <router-link v-if="showButtons.mobilePreview" to="/admin/edit/preview/mobile" class="navbar-item" :class="{'is-active-page': currentRoute === 'previewMobile'}">
                    <i class="fas fa-mobile"></i>
                </router-link>
                <a v-if="showButtons.openLink" class="navbar-item" :href="pageLink" target="_blank">
                    <i class="fas fa-share-square"></i> <span class="item-text">Open</span>
                </a>
                <a 
                    v-if="showButtons.copyLink" 
                    class="navbar-item copy-button" 
                    :data-clipboard-text="pageLink"
                >
                    <i class="fas fa-copy"></i> <span class="item-text">Copy</span>
                </a>
                <a v-if="showButtons.closeLink" class="navbar-item" @click="setContext">
                    <i class="fas fa-times-circle"></i>
                </a>
                <a v-if="showButtons.linkOptions" class="navbar-item" @click="setContext('pagelink')">
                    <i class="fas fa-link"></i>
                </a>
                <a v-if="showButtons.logout" class="navbar-item" @click="logout()">
                    <i class="fas fa-sign-out-alt"></i>
                </a>
                <a v-if="showButtons.pageLink" 
                    class="page-link-container" 
                    :href="pageLink"
                    target="_blank"
                >
                 {{ pageLink }}
                </a>
            </div>
            <div class="navbar-end on-the-right is-pulled-right is-hidden-mobile">
                <div class="navbar-item">
                    <div class="buttons" @click="logout()">
                        <a class="button is-danger is-rounded is-small">
                            <strong>Logout</strong>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProgressBar from "progressbar.js";
import ClipboardJS from "clipboard";
import { mixin as VueClickAway } from "vue3-click-away";
import { debounce } from "lodash-es";

export default {
    name: "Navbar",
    mixins: [VueClickAway],
    data: function() {
        return {
            dropdown: false,
            progressCircle: null,
            context: null,
            copyBtn: null,
            ignoreClickaway: true
        }
    },
    computed: {
        ...mapGetters({
            getLogo: "layout/getLogo",
            getUser: "user/getUser",
            isLoggedIn: "auth/isLoggedIn",
            getPage: "user/getPage",
            upload: "layout/getUpload"
        }),
        progress: function() {
            return this.upload ? this.upload.progress || 0 : 0;
        },
        logo: function() {
            return this.getLogo.white
        },
        currentRoute: function() {
            return this.$route.name;
        },
        desktop: function() {
            return this.$route.name === 'edit';
        },
        page: function() {
            return this.getPage({index: 0});
        },
        banner: function() {
            return this.page ? this.page.design.banner : { show: false };
        },
        pageLink: function() {
            if(!this.page) return null;
            return "https://thegood.link/" + this.page.handle;
        },
        showButtons: function() {
            var stats, user, progress, edit, editing, mobilePreview, pageLink, closeLink, 
            linkOptions, openLink, copyLink, logout;

            stats = true;
            user = true;
            progress = false;
            edit = true;
            editing = true;
            mobilePreview = false;
            pageLink = false;
            closeLink = false;
            linkOptions = true;
            openLink = false;
            copyLink = false;
            logout = true;

            switch(this.currentRoute) {
                case "edit":
                case "previewMobile":
                    logout = false;
                    mobilePreview = true;
                    break;

                case "user":
                    linkOptions = false;
                    break;
            } 

            switch(this.context) {
                case "pagelink":
                    stats = user = progress = edit = editing = mobilePreview = linkOptions = logout = false;
                    openLink = copyLink = closeLink = pageLink = true;
                    break;
            }

            return { stats, user, progress, edit, editing, mobilePreview, pageLink, closeLink, linkOptions, openLink, copyLink, logout }
        }
    },
    methods: {
        ...mapActions({
            doLogout: "auth/doLogout",
            notify: "user/notify"
        }),
        logout: function() {
            this.doLogout()
            .then(() => {
                this.$router.push({ name: "login"});
            })
            .catch(err => {
                console.error(err);
            })
        },
        toggleDropdown: function(menu) {
            //console.log(menu);
            this.dropdown = this.dropdown === menu ? false : menu;
            if(this.dropdown === "edit") {
                this.ignoreClickaway = true;
                setTimeout(() => {
                    //console.log(this.ignoreClickaway);
                    this.ignoreClickaway = false;
                    //console.log(this.ignoreClickaway);
                }, 500);
            }
        },
        dismissDropdown: function() {
            this.dropdown = false;
            this.ignoreClickaway = true;
            //console.log("dismissing", this.dropdown);
        },
        onClickAway: debounce(function() {
            if(!this.ignoreClickaway) this.dismissDropdown();
        }, 100),
        setContext: function(context) {
            this.context = context || null;

            switch(this.context) {
                case "pagelink":
                    this.setupCopyBtn();
                    break;
            }

            this.dismissDropdown();
        },
        copied: function() {
            //console.log("copied");
            this.notify({style: "success", text: "Copied!"});
        },
        emitButtonClick: function({action, elem}) {
            this.dismissDropdown();
            this.$emit("buttonClicked", { action ,elem });
        },
        setupCopyBtn: function() {
            this.copyBtn = new ClipboardJS(".copy-button");
            this.copyBtn.on('success', (e) => {
                this.copied();
                e.clearSelection();
            });

            this.copyBtn.on('error', function(e) {
                console.error('Action:', e.action);
                console.error('Trigger:', e.trigger);
            });
        },
        setup: function() {
            //console.log("setting up navbar");
            this.progressCircle = new ProgressBar.Circle("#progress", {
                color: '#1D2D44',
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 10,
                trailWidth: 0,
                easing: 'easeInOut',
                fill: 'rgba(0,0,0, 0.8)',
                duration: 1400,
                text: {
                    autoStyleContainer: false
                },
                from: { color: '#1D2D44', width: 1 },
                to: { color: '#1B63B3', width: 10 },
                // Set default step function for all animate calls
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    var value = Math.round(circle.value() * 100);

                    switch(value) {
                        case 0:
                            circle.setText('');
                            break;

                        case 100:
                            circle.setText('&check;');
                            break;

                        default:
                            circle.setText(value);

                    }
                }
            });
            this.progressCircle.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
            this.progressCircle.text.style.fontSize = '1rem';
            this.progressCircle.text.style.fontWeight = "bold";
            this.progressCircle.text.style.color = '#00d1b2';
        }
    },
    mounted: function() {
        this.setup();
        window.navbar = this;
    },
    watch: {
        progress: function(newVal) {
            this.progressCircle.animate(newVal);
        }
    }
}
</script>

<style lang="scss" scoped>

    $navbar-breakpoint: calc(300px - (2 * $gap));
    $highlightColor: rgba(black, 0.2);

    nav.is-very-dark {
        background-color: rgba(black, 0.8);

        .navbar-menu {
            background-color: rgba(black, 0.1);
        }
    }

    .navbar-start {
        position: relative;

        &.in-the-middle {
            margin-left: auto;
            margin-right: auto;
            background-color: rgba(black, 0.05);
        }

        &.mobile-width {
            min-width: 350px;
            width: 100vw;
            max-width: 400px;
            
            > .navbar-item {
                flex: 1;
                min-width: 20%;
                text-align: center;
                justify-content: center;

                &.is-active-page, &.is-active-page:focus, &.is-active-page:hover {
                    background-color: $highlightColor !important;
                }
            }

            .has-dropdown {
                .navbar-link {
                    min-width: 100%;
                    justify-content: center;
                }

                .navbar-item {
                    i {
                        margin-right: 10px;
                    }
                }
            }

            .navbar-dropdown {
                margin-left: -100%;
                margin-right: 100%;

                &.is-pulled-right {
                    margin-left: -50%;
                    margin-right: unset;
                }
            }

            .is-active-page, .is-active-page:focus {
                background-color: $highlightColor;
            }
        }
    }

    $progressWidth: 35px;

    #progress {
        opacity: 1;
        border-radius: 50%;
        position: relative;
        max-width: $progressWidth;
        width: $progressWidth;
        height: $progressWidth;
    }

    .navbar-item.is-disabled {
        pointer-events: none;
        opacity: 0.4;
        cursor: not-allowed;
    }

    .on-the-left, .on-the-right {
        width: 20vw;
        max-width: 150px;
        min-width: 150px;
    }

    .navbar-item {
        cursor: pointer;

        i[class^="fa"] {
            margin-right: 5px;

            &:only-child {
                margin-right: 0px;
            }
        }
    }

    .page-link-container {
        position: absolute;
        bottom: 100%;
        display: flex;
        padding: 5px;
        background-color: rgba(black,0.8);
        right: 0px;
        left: 0px;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        width: 100%;
        text-align: center;
        color: white;
        cursor: pointer;
        border: none;
        padding-bottom: 7px;
        font-size: 0.9rem;

        &:hover, &:visited {
            color: white;
        }
    }
</style>